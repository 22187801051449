(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/buttons/mask-toggle-button.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/buttons/mask-toggle-button.js');
"use strict";


const {
  ReactButton: Button
} = svs.ui;
const {
  clx
} = svs.components.lbUtils;
const {
  useBestBetRows
} = svs.components.poolBetting.bestBetRows.hooks;
const MaskToggleButton = _ref => {
  let {
    className,
    enableReMasking,
    isDrawFinalized
  } = _ref;
  const {
    clearMaskedOutcomes,
    maskAllOutcomes,
    hasMaskedOutcomes
  } = useBestBetRows();
  const maskText = isDrawFinalized ? 'Rätta om spelet' : 'Dölj resultat';
  const unmaskText = isDrawFinalized ? 'Rätta spelet' : 'Visa alla resultat';
  if (!hasMaskedOutcomes && !enableReMasking) {
    return null;
  }
  return React.createElement(Button, {
    block: true,
    className: clx('pb_best_bet_rows__buttons__mask_toggle_button', className, {
      'pb_best_bet_rows__buttons__mask_toggle_button--unmasked': !hasMaskedOutcomes
    }),
    inverted: true,
    onClick: hasMaskedOutcomes ? clearMaskedOutcomes : maskAllOutcomes
  }, hasMaskedOutcomes ? unmaskText : maskText);
};
setGlobal('svs.components.poolBetting.bestBetRows.buttons.MaskToggleButton', MaskToggleButton);

 })(window);