(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/single-row.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/single-row.js');
"use strict";


const {
  Outcome
} = svs.components.lbUi.outcome;
const {
  signIndexToOutcome
} = svs.components.sport.tipsenShared;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  hasValidOutcomePair
} = svs.components.poolBetting.utils;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const composeOutcomeLabel = _ref => {
  let {
    outcome = [],
    eventTypeId
  } = _ref;
  if (!Array.isArray(outcome)) {
    return '';
  }
  if (outcome.length === 1) {
    return signIndexToOutcome[eventTypeId][outcome[0]];
  }
  return "".concat(signIndexToOutcome[eventTypeId][outcome[0]], " - ").concat(signIndexToOutcome[eventTypeId][outcome[1]]);
};
const isSelected1X2 = _ref2 => {
  let {
    resultOutcome,
    outcome
  } = _ref2;
  if (!resultOutcome || !resultOutcome.length) {
    return false;
  }
  return parseInt(parseInt(resultOutcome[0], 10), 10) === outcome;
};
const isSelected = _ref3 => {
  let {
    outcome,
    resultOutcomes,
    index,
    eventTypeId
  } = _ref3;
  let isSelected;
  switch (eventTypeId) {
    case EventTypeId.RESULT:
      isSelected = hasValidOutcomePair({
        outcomePairs: [outcome.join('-')],
        resultOutcome: resultOutcomes === null || resultOutcomes === void 0 ? void 0 : resultOutcomes[index],
        maxOutcomeIndex: 10
      });
      break;
    case EventTypeId.EVENT_1X2:
      isSelected = isSelected1X2({
        resultOutcome: resultOutcomes === null || resultOutcomes === void 0 ? void 0 : resultOutcomes[index],
        outcome: outcome[0]
      });
      break;
    default:
      isSelected = false;
  }
  return isSelected;
};
const SingleRow = React.memo(_ref4 => {
  let {
    className,
    eventTypeId,
    resultOutcomes,
    rowNumber,
    rowOutcomes,
    topic
  } = _ref4;
  const [productBranding] = useBranding();
  return React.createElement("div", {
    className: ['pb_best_bet_rows__row'].concat(className || []).join(' ')
  }, rowNumber && React.createElement("div", {
    className: "pb_best_bet_rows__row_header"
  }, rowNumber), React.createElement("div", {
    className: "pb_best_bet_rows__row_topic"
  }, topic), React.createElement("div", {
    className: "pb_best_bet_rows__row_outcomes"
  }, rowOutcomes.map((outcome, index) =>
  React.createElement("div", {
    className: "pb_best_bet_rows__row_outcome_container",
    key: "pb_best_bet_rows__row_outcome_container_".concat(outcome).concat(index)
  }, React.createElement(Outcome, {
    branding: productBranding,
    className: "pb_best_bet_rows__row_outcome",
    isSelected: isSelected({
      outcome,
      resultOutcomes,
      index,
      eventTypeId
    })
    ,
    key: "".concat(outcome).concat(index),
    sign: composeOutcomeLabel({
      outcome,
      eventTypeId
    })
  })))));
}, (prevProps, nextProps) => prevProps.rowOutcomes.join('') === nextProps.rowOutcomes.join('') && prevProps.resultOutcomes.join('') === nextProps.resultOutcomes.join('') && prevProps.topic === nextProps.topic && prevProps.className === nextProps.className);
setGlobal('svs.components.poolBetting.bestBetRows.SingleRow', SingleRow);

 })(window);